import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import MaxWidthContainer from './Containers/MaxWidthContainer'
import clsx from 'clsx'
import CTAContact from './call-to-actions/CTAContact'
import LogoLight from '../assets/logo-light.svg'
import SingleColumnNavigationFlyoutMenu from './Navigation/SingleColumnNavigationFlyoutMenu'
import { useApplications } from '../utils/data'

export default function Layout({
	children = undefined,
	style,
}: {
	children: null | any
	style?: any
}) {
	// #region STATE
	const [headerTransparent, setHeaderTransparent] = useState<boolean>(true)
	// #endregion

	// #region HOOKS
	const applications = useApplications()
	// #endregion

	// #region COMPUTED
	// #endregion

	// #region FUNCTIONS
	const handleScroll = () => {
		setHeaderTransparent(window.scrollY < 16)
	}

	// #endregion

	// #region EFFECTS
	useEffect(() => {
		window.addEventListener('scroll', handleScroll, { passive: true })

		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])
	// #endregion

	return (
		<div className='min-h-screen'>
			<div
				className={clsx(
					'fixed z-50 w-full',
					// headerTransparent ? '' : 'backdrop-blur-xl bg-white bg-opacity-25'
					'backdrop-blur-xl bg-white bg-opacity-0'
				)}
			>
				<MaxWidthContainer
					className='p-6 flex flex-row justify-between items-center'
					style={{
						height: 'var(--header-height)',
					}}
				>
					<Link
						to='/'
						className='w-32'
					>
						<LogoLight
							className='w-full h-20'
							fill='white'
							fillcolor='white'
						/>
					</Link>

					{/* <NavigationLinks /> */}

					<div className='flex flex-row items-center space-x-2 md:space-x-8'>
						<SingleColumnNavigationFlyoutMenu
							title='Applications'
							items={applications.map((application: any) => ({
								name: application.name,
								description: application.description,
								href: application.link,
							}))}
						/>
						<CTAContact />
					</div>
				</MaxWidthContainer>
			</div>

			<div style={style}>{children}</div>

			<MaxWidthContainer className='pb-28 md:pb-36 lg:pb-52 pt-12 relative overflow-hidden'>
				{/*  */}
				{/* FOOTER  */}
				{/*  */}

				<div className='grid grid-cols-1 md:grid-cols-3'>
					<div className=''>
						<LogoLight
							className='w-24'
							fill='white'
							fillcolor='white'
						/>

						{/* <div className='mt-3 text-xs'>Strive for better</div> */}
						<div className='mt-4 text-xs text-gray-600'>
							Shield Polymers. All rights reserved. ©{' '}
							{new Date().getFullYear()}
						</div>
					</div>

					{/* <div className='col-span-2 flex flex-row justify-end space-x-12'>
						<div>
							<ul className='text-sm space-y-2'>
								<li>
									<Link to='#'>About</Link>
								</li>
								<li>
									<Link to='#'>Features</Link>
								</li>
								<li>
									<Link to='#'>Process</Link>
								</li>
								<li>
									<Link to='#'>Testimonials</Link>
								</li>
								<li>
									<Link to='#'>FAQs</Link>
								</li>
							</ul>
						</div>

						<div>
							<ul className='text-sm space-y-2'>
								<li>
									<Link to='#'>Company</Link>
								</li>
								<li>
									<Link to='#'>Privacy Policy</Link>
								</li>
								<li>
									<Link to='#'>Terms & Conditions</Link>
								</li>
							</ul>
						</div>

						<div className='flex flex-row space-x-4'>
							<FiTwitter size={24} />
							<FiLinkedin size={24} />
						</div>
					</div> */}
				</div>

				<div className='absolute bottom-0 left-0 right-0 text-center'>
					<div
						className='translate-y-1/3 font-bold text-[#333] text-[9rem] leading-[6rem] md:text-[14rem] md:leading-[10rem] xl:text-[20rem] xl:leading-[14rem]'
						style={
							{
								// fontSize: '20rem',
								// lineHeight: '14rem',
							}
						}
					>
						SHIELD
					</div>
				</div>

				{/* <Copyright className='text-xs text-center mt-11' /> */}
			</MaxWidthContainer>
		</div>
	)
}
