import clsx from 'clsx'
import { Link } from 'gatsby'
import React from 'react'

export interface INavigationItemProps {
	text: string | React.ReactNode
	className?: string
	to?: string
}

const NavigationItem = ({ text, className, to }: INavigationItemProps) => {
	// #region STATE
	// #endregion STATE

	// #region HOOKS
	// #endregion HOOKS

	// #region COMPUTED
	const classes = clsx(
		'inline-flex items-center gap-x-1 text-xs leading-6 text-white focus:outline-none',
		className
	)
	// #endregion COMPUTED

	// #region FUNCTIONS
	// #endregion FUNCTIONS

	// #region EFFECTS
	// #endregion EFFECTS

	if (to) {
		return (
			<Link
				to={to}
				className={classes}
			>
				{text}
			</Link>
		)
	}

	return <span className={classes}>{text}</span>
}

export default NavigationItem
