import { graphql, useStaticQuery } from 'gatsby'

const useData = () => {
	const data = useStaticQuery(graphql`
		query DataQuery {
			productLines: allSanityProductLine {
				nodes {
					id
					_id
					name
					slug {
						current
					}
					tagline
					description
					color {
						hex
					}
					productLineImage {
						asset {
							id
							url
						}
					}
					products {
						id
						name
						tagline
						shortDescription
						longDescription
						slug {
							current
						}
						productFeatures {
							_id
							name
							description
						}
					}
				}
			}

			applications: allSanityApplication {
				nodes {
					id
					_id
					name
					description
					link
				}
			}
		}
	`)

	return data
}

export const useProductLines = () => {
	const data = useData()

	return data.productLines.nodes
}
export const useApplications = () => {
	const data = useData()
	return data.applications.nodes
}
