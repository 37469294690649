import React, { useState } from 'react'
import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import clsx from 'clsx'
import NavigationItem from './NavigationItem'
import { Link } from 'gatsby'

export interface ISingleColumnNavigationFlyoutMenuCategoryItem {
	name: string
	description: string
	href: string
	icon: any
	className?: string
	bgColor?: string
}
export interface ISingleColumnNavigationFlyoutMenuProps {
	title: string
	items: ISingleColumnNavigationFlyoutMenuCategoryItem[]
}

const SingleColumnNavigationFlyoutMenu = ({
	title,
	items = [],
}: ISingleColumnNavigationFlyoutMenuProps) => {
	// #region STATE
	const [bgSliderOffsetY, setBgSliderOffsetY] = useState<number>()
	const [bgSliderHeight, setBgSliderHeight] = useState<number>()
	const [popoverBtnWidth, setPopoverBtnWidth] = useState<number>(0)
	// #endregion STATE

	// #region HOOKS
	// #endregion HOOKS

	// #region COMPUTEDa
	// #endregion COMPUTED

	// #region FUNCTIONS
	const onCategorySelection = (btn: HTMLButtonElement, catgeory: any) => {
		setBgSliderOffsetY(btn.offsetTop)
		setBgSliderHeight(btn.clientHeight)
	}
	// #endregion FUNCTIONS

	// #region EFFECTS
	// #endregion EFFECTS

	return (
		<Popover className='relative'>
			<Popover.Button
				ref={(ref) => {
					if (!ref) {
						setPopoverBtnWidth(0)
						return
					}

					setPopoverBtnWidth(ref.clientWidth)
				}}
				className='mb-0.5'
			>
				<NavigationItem text={title} />
			</Popover.Button>

			<Transition
				as={Fragment}
				enter='transition ease-out duration-200'
				enterFrom='opacity-0 translate-y-1'
				enterTo='opacity-100 translate-y-0'
				leave='transition ease-in duration-150'
				leaveFrom='opacity-100 translate-y-0'
				leaveTo='opacity-0 translate-y-1'
			>
				<Popover.Panel
					className={clsx(
						'absolute z-10 mt-5 w-screen max-w-max',
						'left-1/2 -translate-x-1/2'
					)}
				>
					<div className='w-screen max-w-[250px] overflow-hidden rounded-lg bg-gray-100 text-sm leading-6 shadow-lg ring-1 ring-gray-900/5'>
						<div
							style={{
								width: 6,
								height: 14,
								position: 'absolute',
								left: '50%',
								transform: 'rotate(-90deg)',
								top: -10,
							}}
						>
							<svg
								width='100%'
								height='100%'
								viewBox='0 0 12 28'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M7.29289 13.2929 0 6v16l7.29289-7.2929c.39053-.3905.39053-1.0237 0-1.4142Z'
									fill='#f0f3f6'
								></path>
								<path
									fillRule='evenodd'
									clipRule='evenodd'
									d='m0 27.657 9.41421-9.4142c2.34319-2.3432 2.34319-6.1422.00001-8.48532L0 .343262V6.00012l6.58579 6.58578c.78105.7811.78105 2.0474 0 2.8284L0 22.0001v5.6569Z'
									fill='#f0f3f6'
								></path>
							</svg>
						</div>

						<div
							className='relative p-1'
							style={
								{
									// '--bgSliderOffsetY': bgSliderOffsetY,
									// '--siteSubMenuTriggerOffsetY': '96px',
									'--siteSubMenuTriggerOffsetY': `${bgSliderOffsetY}px`,
									'--siteSubMenuTriggerOffsetYCenter': '144px',
									'--siteSubMenuTriggerBackgroundHeight': `${bgSliderHeight}px`,
									//
									// '--siteSubMenuBackgroundColor': '#eff3f9',
									// '--siteSubMenuTriggerOffsetY': '0px',
									// '--siteSubMenuTriggerOffsetYCenter': '42px',
									// '--siteSubMenuTriggerBackgroundHeight': '84px',
									'--siteSubMenuTriggerTransition':
										'transform 150ms linear, height 150ms',
									'--siteSubMenuNavWidth': '264px',
									'--siteSubMenuSectionListWidth': '546px',
									'--siteSubMenuNavGap': '4px',
									'--siteSubMenuSectionListBackgroundRadius': '4px',
									'--siteNavIconLabelLineHeight': '24px',
									'--siteNavIconBodyLineHeight': '16px',
								} as React.CSSProperties
							}
						>
							<div className='relative'>
								<div id={`${title}-categories`}>
									{items.map((item) => {
										return (
											<Link
												key={item.name}
												id={`${title}-category:${item.name}`}
												type='button'
												to={item.href}
												target='_blank'
												className={clsx(
													'w-full hover:bg-gray-200 group relative flex gap-x-6 rounded-lg p-2 text-left'
												)}
												onMouseEnter={(e) => {
													e.target.localName === 'button' &&
														onCategorySelection(
															e.target as HTMLButtonElement,
															item
														)
												}}
											>
												<div>
													<span
														className='font-medium text-gray-800'
													>
														{item.name}
													</span>
													<p className='text-xs text-gray-500'>{item.description}</p>
												</div>
											</Link>
										)
									})}
								</div>
							</div>
						</div>
					</div>
				</Popover.Panel>
			</Transition>
		</Popover>
	)
}

export default SingleColumnNavigationFlyoutMenu
